import { Combobox, ComboboxButton, ComboboxInput } from '@headlessui/react';
import { useNavigate, useSearchParams } from '@remix-run/react';
import { CloseIcon, SearchIcon } from '@storefront/ui-components/shared/icons';
import { checkIsSearchPage } from '@storefront/util/browser';
import { type FC, useEffect, useRef, useState } from 'react';
import { useStorefrontSettings } from '../../../app/utils/use-storefront-settings';
import { IconButton } from '../shared/buttons';

export const SearchBarNavigate: FC = () => {
  const navigate = useNavigate();
  const searchRef = useRef<HTMLInputElement>(null);

  const isSearchPage = checkIsSearchPage();
  const storefrontSettings = useStorefrontSettings();
  const [searchParams] = useSearchParams();
  const [inputValue, setInputValue] = useState('');

  const queryTerm = searchParams.get('query');

  useEffect(() => {
    if (isSearchPage) {
      setInputValue(queryTerm as string);
    }

    if (queryTerm === null) {
      setInputValue('');
    }
  }, [queryTerm, isSearchPage]);

  const encodedQuery = (term: string) => {
    const trimmedQuery = term.trim();
    searchParams.set('query', decodeURIComponent(trimmedQuery));

    return searchParams.toString();
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setInputValue((prev) => {
      if (prev === newValue) return prev;

      return newValue;
    });

    if (isSearchPage) {
      if (newValue.length >= 2) {
        navigate(`/search?${encodedQuery(newValue)}`);
      } else if (newValue.length === 0) {
        navigate('/search');
      }

      return;
    }
  };

  const handleClearSearch = () => {
    setInputValue('');
    navigate('/search', { replace: true });
  };

  const keyDownEvent = isSearchPage
    ? {
        onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => {
          if (event.key === 'Enter') {
            event.preventDefault();
          }
        },
      }
    : {};

  // Check if our queryTerm is null too
  const showClearButton = (inputValue || (queryTerm ?? '')).length > 0 && queryTerm !== null;

  return (
    <div className="flex justify-center items-center">
      <Combobox>
        <div className="w-[290px] md:w-[420px] transition-all duration-100 ease-in-out">
          <div className="w-full rounded-lg relative">
            <div className="flex items-center relative">
              <div className="absolute">
                <SearchIcon className="w-6 h-6 ml-2 md:ml-3" />
              </div>
              <ComboboxInput
                ref={searchRef}
                className={`
                  w-full !h-10
                  px-[30px] py-2 pl-[46px]
                  text-sm
                  bg-gray-100 rounded-full
                  outline-none border-none
                  focus:border-none focus:ring-0
                `}
                autoComplete="off"
                autoFocus={isSearchPage}
                placeholder={`Search ${storefrontSettings?.name} auctions`}
                value={inputValue}
                onChange={handleInputChange}
                {...keyDownEvent}
              />

              <ComboboxButton className="group absolute inset-y-0 right-2">
                {showClearButton && (
                  <IconButton
                    className="cursor-pointer h-6 w-6 z-50"
                    icon={() => <CloseIcon />}
                    aria-label="Clear search"
                    onClick={handleClearSearch}
                  />
                )}
              </ComboboxButton>
            </div>
          </div>
        </div>
      </Combobox>
    </div>
  );
};
