import { algoliasearch } from 'algoliasearch';

let client: ReturnType<typeof algoliasearch> | null = null;

const ALGOLIA_TIMEOUTS = {
  CONNECT: 2000,
  READ: 5000,
  WRITE: 30000,
} as const;

if (typeof window !== 'undefined' && window.ENV) {
  client = algoliasearch(
    (window.ENV as Record<string, string>).ALGOLIA_APP_ID,
    (window.ENV as Record<string, string>).ALGOLIA_SEARCH_API_KEY,
    {
      timeouts: {
        connect: ALGOLIA_TIMEOUTS.CONNECT,
        read: ALGOLIA_TIMEOUTS.READ,
        write: ALGOLIA_TIMEOUTS.WRITE,
      },
    },
  );
}

export const useAlgoliaClient = () => {
  return client;
};

export const getAlgoliaClient = () => {
  return algoliasearch(process.env.ALGOLIA_APP_ID as string, process.env.ALGOLIA_SEARCH_API_KEY as string);
};
