import { Link } from '@remix-run/react';
import type { UserLocation } from '@storefront/ui-components/hooks/useUserLocation';
import { OutlineLocationOnIcon } from '@storefront/ui-components/shared/icons';
import clsx from 'clsx';
import type { FC, PropsWithChildren } from 'react';
import { ClientOnly } from 'remix-utils/client-only';
import type { Customer } from 'storefront-client';
import { useStorefrontSettings } from '~/utils/use-storefront-settings';
import InstantSearchWrapper from '../../../app/components/layout/InstantSearchWrapper';
import type { SearchResults } from '../../../app/components/search/types';
import { useTooltip } from '../../../app/utils/use-tooltip';
import { checkIsSearchPage } from '../../util';
import type { TogglePayload, ToggleableTargets } from '../providers/storefront-provider';
import { IconButton } from '../shared/buttons';
import { Image } from '../shared/images/Image';
import { Tooltip } from '../shared/tooltip';
import { HeaderUserMenu } from './HeaderUserMenu';
import { SearchBar } from './SearchBar';
import { SearchBarNavigate } from './SearchBarNavigate';

export interface HeaderProps {
  customer?: Customer;
  location?: UserLocation;
  toggleSearch?: (isOpen: boolean) => void;
  toggleLocation?: (payload?: TogglePayload<ToggleableTargets.location>) => void;
  isSearchVisible?: boolean;
  results?: SearchResults;
}

export const Header: FC<PropsWithChildren<HeaderProps>> = ({ customer, location, toggleLocation, results }) => {
  const storefrontSettings = useStorefrontSettings();
  const isSearchPage = checkIsSearchPage();
  const { isTooltipVisible } = useTooltip();

  const LocationIconButton = location && toggleLocation && (
    <IconButton
      className={clsx('header-postal-code', { 'pl-1.5': location.postalCode })}
      icon={OutlineLocationOnIcon}
      suffix={location.postalCode && <span className="relative text-sm tracking-tighter">{location.postalCode}</span>}
      onClick={() => toggleLocation({ open: true })}
    />
  );

  return (
    <nav className="nav-bar flex h-12 sm:h-16 w-full items-center justify-between gap-2 sm:gap-4 border bg-white px-2 xs:px-4 sm:px-8">
      <Link to="/">
        <>
          <Image alt="primary-logo" src={storefrontSettings?.logo?.url} className="h-5 min-w-36 hidden sm:block" />
          <Image alt="primary-logo" src={storefrontSettings?.icon?.url} className="h-5 min-w-6 block sm:hidden" />
        </>
      </Link>

      <div className="flex flex-row align-middle items-center justify-between w-full gap-2">
        <div className="flex flex-grow xs:justify-center md:justify-end">
          <ClientOnly>
            {() => (
              <>
                {!isSearchPage ? (
                  <InstantSearchWrapper>
                    <SearchBar />
                  </InstantSearchWrapper>
                ) : (
                  <SearchBarNavigate />
                )}
              </>
            )}
          </ClientOnly>
        </div>

        <div className="flex items-center gap-2">
          {isTooltipVisible && location?.postalCode ? (
            <Tooltip
              isOpen
              aria-label="Delivery postal code confirmation"
              content="Does this match your delivery postal code?"
            >
              {LocationIconButton}
            </Tooltip>
          ) : (
            LocationIconButton
          )}

          {(customer || (location && toggleLocation)) && (
            <HeaderUserMenu customer={customer} location={location} toggleLocation={toggleLocation} />
          )}
        </div>
      </div>
    </nav>
  );
};
