import type { HTMLAttributes } from 'react';

export default ({ color = 'currentColor', ...props }: HTMLAttributes<HTMLOrSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>Search</title>
    <path
      d="M21.7072 19.826L16.2587 15.4012C18.5041 12.3303 18.273 7.93849 15.4662 5.16473C13.9803 3.67879 11.999 2.85327 9.88569 2.85327C7.77235 2.85327 5.7911 3.67879 4.30516 5.16473C1.23422 8.23568 1.23422 13.2549 4.30516 16.3258C5.7911 17.8117 7.77235 18.6373 9.88569 18.6373C11.9 18.6373 13.7822 17.8778 15.2681 16.5239L20.7826 20.9817C20.9147 21.0808 21.0798 21.1468 21.2449 21.1468C21.476 21.1468 21.6741 21.0478 21.8062 20.8827C22.0704 20.5525 22.0374 20.0902 21.7072 19.826ZM9.88569 17.1513C8.1686 17.1513 6.5836 16.4909 5.36183 15.2691C2.85224 12.7595 2.85224 8.69797 5.36183 6.2214C6.5836 4.99963 8.1686 4.33921 9.88569 4.33921C11.6028 4.33921 13.1878 4.99963 14.4096 6.2214C16.9191 8.73099 16.9191 12.7926 14.4096 15.2691C13.2208 16.4909 11.6028 17.1513 9.88569 17.1513Z"
      fill={color}
    />
  </svg>
);
