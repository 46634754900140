import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions } from '@headlessui/react';
import { ArrowRightIcon } from '@heroicons/react/24/solid';
import { useNavigate, useSearchParams } from '@remix-run/react';
import { CloseIcon } from '@storefront/ui-components/shared/icons';
import { buildProductUrl } from '@storefront/util/auction/buildProductUrl';
import { checkIsSearchPage } from '@storefront/util/browser';
import { formatPrice } from '@storefront/util/prices';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { type FC, useRef, useState } from 'react';
import { useClearRefinements, useSearchBox } from 'react-instantsearch';
import { useStorefrontSettings } from '../../../app/utils/use-storefront-settings';
import SearchIcon from '../assets/icons/SearchIcon';
import { useAuctionAutocomplete } from '../hooks/useAuctionAutocomplete';
import { useRegion } from '../hooks/useRegion';
import { IconButton } from '../shared/buttons';
import { Image } from '../shared/images/Image';
import { DiscountBadge } from '../shared/labels/DiscountBadge';

type HitAuction = {
  objectID: string;
  title: string;
  handle: string;
  imageLink: string;
  currentPrice: number;
  originalPrice: number;
};

type SelectedAuction = HitAuction | { type: 'viewAll'; query: string } | null;

export const SearchBar: FC = () => {
  const { auctions } = useAuctionAutocomplete();
  const navigate = useNavigate();
  const { region } = useRegion();
  const searchRef = useRef<HTMLInputElement>(null);
  const [selectedAuction, setSelectedAuction] = useState<SelectedAuction>({
    type: 'viewAll',
    query: '',
  });
  const { query, refine, clear } = useSearchBox();
  const isSearchPage = checkIsSearchPage();
  const storefrontSettings = useStorefrontSettings();
  const [searchParams] = useSearchParams();
  const { refine: clearRefinements } = useClearRefinements();

  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (!isSearchPage) {
      setInputValue(query);
    }
  }, [query, isSearchPage]);

  const encodedQuery = (term: string) => {
    const trimmedQuery = term.trim();
    searchParams.set('query', decodeURIComponent(trimmedQuery));

    return searchParams.toString();
  };
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setInputValue(newValue);

    if (isSearchPage) {
      setSelectedAuction({
        type: 'viewAll',
        query: newValue,
      });

      if (newValue.length >= 2) {
        navigate(`/search?${encodedQuery(newValue)}`);
      } else if (newValue.length === 0) {
        navigate('/search');
      }

      return;
    }

    if (newValue === '') refine('');
    if (newValue.length > 1) refine(newValue);
  };

  const handleClearSearch = () => {
    if (!isSearchPage) {
      refine('');
      clear();
    }

    setInputValue('');
    setSelectedAuction(null);
  };

  const highlightTerm = (text: string, term: string) => {
    const regex = new RegExp(`(${term})`, 'gi');
    return text
      .split(regex)
      .map((part, index) =>
        regex.test(part)
          ? React.createElement('strong', { key: index }, part)
          : React.createElement('span', { key: index }, part),
      );
  };

  const keyDownEvent = isSearchPage
    ? {
        onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => {
          if (event.key === 'Enter') {
            event.preventDefault();
          }
        },
      }
    : {};

  const showClearButton =
    (selectedAuction && 'query' in selectedAuction && selectedAuction.query ? selectedAuction.query : query).length > 0;

  return (
    <div className="flex justify-end items-center w-full">
      <Combobox<SelectedAuction>
        value={selectedAuction}
        onChange={(auction: SelectedAuction) => {
          if (isSearchPage) return;

          if (auction && 'type' in auction && auction.type === 'viewAll') {
            navigate(`/search?${encodedQuery(auction.query)}`);
            if (!isSearchPage) {
              refine(auction.query);
            }
            return;
          }

          if (auction && 'handle' in auction) {
            navigate(buildProductUrl(auction.handle));
            refine(auction.title);
          }
        }}
      >
        <div
          className={clsx(
            'w-[120px] transition-all duration-100 ease-in-out',
            'focus-within:w-[calc(100vw-1rem)] focus-within:absolute focus-within:left-2 md:focus-within:left-0 focus-within:right-0 focus-within:z-10',
            'md:focus-within:relative md:focus-within:w-full',
            'lg:focus-within:w-96',
            {
              'w-[calc(100vw-1rem)] absolute left-2 md:left-0 right-0 z-10 md:relative md:w-full lg:w-96':
                inputValue.length > 0,
            },
          )}
        >
          <div className="w-full rounded-lg relative">
            <div className="flex items-center relative">
              <div className="absolute pointer-events-none">
                <SearchIcon className="w-6 h-6 ml-2 md:ml-3" />
              </div>
              <ComboboxInput
                ref={searchRef}
                className={`
                  w-full !h-10
                  px-[30px] py-2 pl-[46px]
                  text-sm
                  bg-gray-100 rounded-full
                  outline-none border-none
                  focus:border-none focus:ring-0
                `}
                autoComplete="off"
                autoFocus={isSearchPage}
                placeholder={`Search ${storefrontSettings?.name} auctions`}
                value={inputValue}
                onChange={handleInputChange}
                onBlur={() => {
                  if (!isSearchPage && inputValue !== query) {
                    refine(inputValue);
                  }
                }}
                {...keyDownEvent}
              />

              <ComboboxButton className="group absolute inset-y-2 right-2">
                {showClearButton && (
                  <IconButton
                    className="cursor-pointer h-6 w-6 z-20"
                    icon={() => <CloseIcon />}
                    aria-label="Clear search"
                    onClick={handleClearSearch}
                  />
                )}
              </ComboboxButton>
            </div>

            {query.length > 0 && !isSearchPage && (
              <ComboboxOptions
                className={
                  'pb-5 flex flex-col absolute z-10 w-full bg-gray-100 rounded-lg mt-2 shadow-md overflow-hidden'
                }
              >
                <ComboboxOption value={{ type: 'viewAll', query }} className="cursor-pointer">
                  {auctions.length > 0 ? (
                    <div className="ml-4 my-2 text-xs text-gray-600">
                      <span>Press enter to view {`${auctions.length}`} results for</span>
                      <span className="font-bold">{` "${query}"`}</span>
                    </div>
                  ) : (
                    <div className="ml-4 mb-3 mt-3 flex flex-col gap-2 md:flex-row flex-wrap">
                      <span>No results for</span>
                      <span className="font-bold">{` "${query}"`}</span>
                    </div>
                  )}
                </ComboboxOption>
                <div className="max-h-[280px] overflow-y-auto [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none] [mask-image:linear-gradient(to_bottom,black_95%,transparent_100%)] [webkit-mask-image:linear-gradient(to_bottom,black_95%,transparent_100%)]">
                  {(isSearchPage ? [] : auctions).map((auction) => {
                    const percentageDiscount = Math.round(
                      Math.abs((auction.originalPrice - auction.currentPrice) / auction.originalPrice) * 100,
                    );
                    const hasDiscountBiggerThan30Percent = percentageDiscount >= 30;

                    return (
                      <ComboboxOption key={auction.objectID} value={auction}>
                        {({ focus }) => (
                          <div
                            className={`cursor-pointer flex flex-row align-middle items-center justify-between py-1 group ${
                              focus ? 'bg-gray-200' : ''
                            }`}
                          >
                            <div className="flex flex-row w-full justify-between items-center">
                              <div className="flex flex-row align-middle items-center gap-3 px-4 min-w-[64px]">
                                <Image
                                  src={auction.imageLink}
                                  alt={auction.title}
                                  className="w-[64px] h-[64px] object-cover rounded-md min-w-[64px]"
                                />
                                <div>
                                  <div className="text-sm" style={{ lineHeight: 1.3 }}>
                                    {highlightTerm(auction.title, query)}
                                  </div>
                                  <div className="flex flex-row gap-2 mt-1">
                                    <div className="text-gray-500 text-sm">
                                      {formatPrice(auction.currentPrice, {
                                        currency: region.currency_code,
                                      })}
                                    </div>
                                    {hasDiscountBiggerThan30Percent && (
                                      <DiscountBadge percentageDiscount={percentageDiscount} size="small" />
                                    )}
                                  </div>
                                </div>
                              </div>
                              <ArrowRightIcon className={clsx('w-6 h-6 mr-4', focus ? 'opacity-100' : 'opacity-0')} />
                            </div>
                          </div>
                        )}
                      </ComboboxOption>
                    );
                  })}
                </div>
              </ComboboxOptions>
            )}
          </div>
        </div>
      </Combobox>
    </div>
  );
};
