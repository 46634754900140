import { useFetcher } from '@remix-run/react';
import { useSendEvent } from '../../util/analytics/useAnalytics';
import { useCustomer } from './useCustomer';

interface UseLogoutOptions {
  redirect?: string;
}
export const useLogout = (options?: UseLogoutOptions) => {
  const { redirect = '/' } = options || {};
  const { customer } = useCustomer();
  const useSendLogoutEvent = useSendEvent('logout');

  const logoutFetcher = useFetcher<{ key: string }>({ key: 'logout' });
  const logout = () => {
    useSendLogoutEvent({ customer });
    logoutFetcher.submit(
      {
        subaction: 'logout',
        redirect,
      },
      {
        method: 'POST',
        action: '/api/auth',
      },
    );
  };

  return { logoutFetcher, logout };
};
