import { ButtonBase } from '@storefront/ui-components/shared/buttons/ButtonBase';
import { IconButton } from '@storefront/ui-components/shared/buttons/IconButton';
import { HamburgerIcon, LogoutIcon, SignUpIcon, UserIcon } from '@storefront/ui-components/shared/icons';
import { Menu, type MenuProps } from '@storefront/ui-components/shared/menu/Menu';
import { MenuButton } from '@storefront/ui-components/shared/menu/MenuButton';
import { MenuItem, type MenuItemRenderProps } from '@storefront/ui-components/shared/menu/MenuItem';
import { MenuItemIcon } from '@storefront/ui-components/shared/menu/MenuItemIcon';
import { MenuItems } from '@storefront/ui-components/shared/menu/MenuItems';
import clsx from 'clsx';
import type { FC } from 'react';
import type { Customer } from 'storefront-client';
import { useLogin } from '../hooks/useLogin';
import { useLogout } from '../hooks/useLogout';
import { useSignUp } from '../hooks/useSignup';
import type { TogglePayload, ToggleableTargets } from '../providers/storefront-provider';
import { LocationPinIcon } from '../shared/icons/LocationPinIcon';

interface HeaderUserMenuProps extends MenuProps {
  location?: {
    postalCode: string;
  };
  toggleLocation?: (payload?: TogglePayload<ToggleableTargets.location> | undefined) => void;
  customer?: Customer;
}

export const HeaderUserMenu: FC<HeaderUserMenuProps> = ({ customer, location, toggleLocation, ...props }) => {
  const { toggleLoginModal } = useLogin();
  const { toggleSignUpModal } = useSignUp();
  const { logout } = useLogout();
  const isLoggedIn = customer?.id;

  return (
    <Menu {...props}>
      <MenuButton>
        <IconButton
          id="user-menu-button"
          aria-label="open user menu"
          icon={HamburgerIcon}
          className="h-9 w-9 sm:h-10 sm:w-10"
        />
      </MenuButton>
      <MenuItems className="position-bottom-right">
        {location && toggleLocation && (
          <MenuItem
            className="block sm:hidden"
            item={(itemProps: MenuItemRenderProps) => (
              <ButtonBase
                {...itemProps}
                className={clsx('!justify-start', itemProps.className)}
                onClick={() => {
                  toggleLocation({ open: true });
                }}
              >
                <MenuItemIcon icon={LocationPinIcon} />
                {location.postalCode ?? 'Set Location'}
              </ButtonBase>
            )}
          />
        )}

        {!isLoggedIn && (
          <>
            <MenuItem
              className="sm:!border-t-0"
              item={(itemProps: MenuItemRenderProps) => (
                <ButtonBase
                  {...itemProps}
                  className={clsx('!justify-start', itemProps.className)}
                  onClick={() => toggleSignUpModal({ open: true })}
                >
                  <MenuItemIcon icon={SignUpIcon} />
                  Join
                </ButtonBase>
              )}
            />
            <MenuItem
              item={(itemProps: MenuItemRenderProps) => (
                <ButtonBase
                  {...itemProps}
                  className={clsx('!justify-start', itemProps.className)}
                  onClick={() => toggleLoginModal({ open: true })}
                >
                  <MenuItemIcon icon={UserIcon} />
                  Log in
                </ButtonBase>
              )}
            />
          </>
        )}

        {isLoggedIn && (
          <>
            {/* <MenuItem
              item={(itemProps: MenuItemRenderProps) => (
                <ButtonBase
                  {...itemProps}
                  className={clsx('!justify-start', itemProps.className)}
                  onClick={() => {
                    // TODO: implement account
                  }}
                >
                  <MenuItemIcon icon={UserIcon} />
                  View Account
                </ButtonBase>
              )}
            /> */}
            <MenuItem
              item={(itemProps: MenuItemRenderProps) => (
                <ButtonBase {...itemProps} className={clsx('!justify-start', itemProps.className)} onClick={logout}>
                  <MenuItemIcon icon={LogoutIcon} />
                  Log out
                </ButtonBase>
              )}
            />
          </>
        )}
      </MenuItems>
    </Menu>
  );
};
