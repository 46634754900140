import { useAlgoliaClient } from '@storefront/ui-components/hooks/useAlgoliaClient';
import { useEnv } from '@storefront/ui-components/hooks/useEnv';
import useUserLocation from '@storefront/ui-components/hooks/useUserLocation';
import { checkIsSearchPage } from '@storefront/util/browser';
import type { FC } from 'react';
import { Configure, InstantSearch } from 'react-instantsearch';
import { useStorefrontSettings } from '../../utils/use-storefront-settings';
import { getRouting } from '../search/routing';

interface InstantSearchWrapperProps {
  children: React.ReactNode;
}

const InstantSearchWrapper: FC<InstantSearchWrapperProps> = ({ children }) => {
  const { env } = useEnv();
  const searchClient = useAlgoliaClient();
  const routing = getRouting(env.ALGOLIA_INDEX_NAME as string);
  const location = useUserLocation();
  const storefrontSettings = useStorefrontSettings();

  const isSearchPage = checkIsSearchPage();
  // Only apply routing on search pages to prevent interference with other page types
  const finalRouting = isSearchPage ? routing : false;

  if (!searchClient) {
    return null;
  }

  const finalFilter = location?.warehouseId
    ? `warehouseId:${location.warehouseId}`
    : `partnerId:${storefrontSettings?.partner_id}`;

  return (
    <InstantSearch searchClient={searchClient} indexName={env.ALGOLIA_INDEX_NAME} routing={finalRouting}>
      <Configure optionalFilters={[finalFilter]} />
      {children}
    </InstantSearch>
  );
};

export default InstantSearchWrapper;
