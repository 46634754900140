import type { UiState } from 'instantsearch.js';
import { history as historyRouter } from 'instantsearch.js/es/lib/routers';

type RouteState = {
  query?: string;
  page?: string;
  collection?: string[];
  productCondition?: string[];
  sortBy?: string;
  hitsPerPage?: string;
};

const routeStateDefaultValues: RouteState = {
  query: '',
  page: '1',
  collection: undefined,
  productCondition: undefined,
  sortBy: 'auction',
  hitsPerPage: '20',
};

const router = historyRouter<RouteState>({
  cleanUrlOnDispose: false,
  createURL({ qsModule, routeState, location }): string {
    const { protocol, hostname, port = '', pathname, hash } = location;
    const portWithPrefix = port === '' ? '' : `:${port}`;

    const finalBaseUrl = `${protocol}//${hostname}${portWithPrefix}${pathname.replace(/\/$/, '')}`;

    const queryParameters: Partial<RouteState> = {};

    if (routeState.query && routeState.query !== routeStateDefaultValues.query) {
      queryParameters.query = encodeURIComponent(routeState.query);
    }
    if (routeState.page && routeState.page !== routeStateDefaultValues.page) {
      queryParameters.page = routeState.page;
    }
    if (routeState.collection && routeState.collection !== routeStateDefaultValues.collection) {
      queryParameters.collection = routeState.collection.map(encodeURIComponent);
    }
    if (routeState.productCondition && routeState.productCondition !== routeStateDefaultValues.productCondition) {
      queryParameters.productCondition = routeState.productCondition.map(encodeURIComponent);
    }
    if (routeState.sortBy && routeState.sortBy !== routeStateDefaultValues.sortBy) {
      queryParameters.sortBy = routeState.sortBy;
    }
    if (routeState.hitsPerPage && routeState.hitsPerPage !== routeStateDefaultValues.hitsPerPage) {
      queryParameters.hitsPerPage = routeState.hitsPerPage;
    }

    const queryString = qsModule.stringify(queryParameters, {
      addQueryPrefix: true,
      arrayFormat: 'repeat',
    });

    return `${finalBaseUrl}${queryString}${hash}`;
  },

  parseURL({ qsModule, location }): RouteState {
    const queryParameters = qsModule.parse(location.search.slice(1));
    const { query = '', page = '1', collection = [], productCondition = [], sortBy, hitsPerPage } = queryParameters;

    const allCollections = (Array.isArray(collection) ? collection : [collection].filter(Boolean)) as string[];
    const allProductConditions = (
      Array.isArray(productCondition) ? productCondition : [productCondition].filter(Boolean)
    ) as string[];

    return {
      query: decodeURIComponent(query as string),
      page: page as string,
      collection: allCollections.map(decodeURIComponent),
      productCondition: allProductConditions.map(decodeURIComponent),
      sortBy: sortBy as string,
      hitsPerPage: hitsPerPage as string,
    };
  },
});

const getStateMapping = ({ indexName }: { indexName: string }) => ({
  stateToRoute(uiState: UiState): RouteState {
    const indexUiState = uiState[indexName];
    return {
      query: indexUiState.query,
      page: (indexUiState.page && String(indexUiState.page)) || undefined,
      collection: indexUiState.refinementList?.collection,
      productCondition: indexUiState.refinementList?.productCondition,
      sortBy: indexUiState.sortBy,
      hitsPerPage: (indexUiState.hitsPerPage && String(indexUiState.hitsPerPage)) || undefined,
    };
  },

  routeToState(routeState: RouteState): UiState {
    const refinementList: { [key: string]: string[] } = {};
    if (routeState.collection) {
      refinementList.collection = routeState.collection;
    }
    if (routeState.productCondition) {
      refinementList.productCondition = routeState.productCondition;
    }

    return {
      [indexName]: {
        query: routeState.query,
        page: Number(routeState.page),
        refinementList,
        sortBy: routeState.sortBy,
        hitsPerPage: Number(routeState.hitsPerPage),
      },
    };
  },
});

export const getRouting = (indexName: string) => ({
  router,
  stateMapping: getStateMapping({ indexName }),
});
