import { useMemo } from 'react';
import { useStorefront } from './useStorefront';

export const useSignUp = () => {
  const { state, actions } = useStorefront();

  if (!state.signUp) throw new Error('useSignUp must be used within the StorefrontContext.Provider');

  const signUp = useMemo(() => state.signUp, [state.signUp]);
  const toggleSignUpModal = useMemo(() => actions.toggleSignUpModal, [actions]);

  return { signUp, toggleSignUpModal };
};
